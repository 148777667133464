import { Component, HostListener } from '@angular/core';
import { DossiersService } from '../../services/dossiers/dossiers.service';
import { debounceTime, filter } from 'rxjs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SearchDossiers } from '../../interfaces/SearchDossiers';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CommonModule } from '@angular/common';
import { SharedService } from '../../services/shared/shared.service';
import { NavigationEnd, Router } from '@angular/router';
import { InputListenerDirective } from '../../directives/input-listener/input-listener.directive';


@Component({
  selector: 'app-search-dossiers',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, InputListenerDirective],
  templateUrl: './search-dossiers.component.html',
  styleUrl: './search-dossiers.component.css'
})
export class SearchDossiersComponent 
{
  isVisible:boolean = false
  hideComponent:boolean = false
  idService!:number
  last_item!:string
  last_item_type!:string
  last_item_id!:number
  liste_dossiers:SearchDossiers[] = []
  gestion_dossier:boolean
  copie_message:boolean

  searchForm:FormGroup = new FormGroup({
    searchValue: new FormControl("")
  })

  constructor
  (
    private dossiersService: DossiersService,
    private navigationService: NavigationService,
    private router: Router,
    private sharedService: SharedService
  ) 
  {
    this.sharedService.reloadSidebarData.subscribe(data => {
      if( data == true )
      {
        this.updateLastDossier()
      }
    })

    this.idService = this.sharedService.getIdService()
    
    const { gestion_dossier, copie_message } = this.sharedService.getUserRights()

    this.gestion_dossier = gestion_dossier
    
    this.copie_message = copie_message

    this.updateLastDossier()
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) 
  {
    this.isVisible = false
  }
  
  ngOnInit()
  {
    this.processGetRefDossiers()
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if(event.url.includes("suivi-partenariat"))
      {
        this.hideComponent = true
      }
      else
      {
        this.hideComponent = false
      }
      this.updateLastDossier()
    })
  }

  updateLastDossier()
  {
    const last_item = this.sharedService.getLastItem()?.toString()
    this.last_item = last_item

    const last_item_type = this.sharedService.getLastItemType()?.toString()
    this.last_item_type = last_item_type

    const last_item_id = parseInt(this.sharedService.getLastItemId())
    this.last_item_id = last_item_id
  }

  processGetRefDossiers()
  {
    this.searchForm.valueChanges
    .pipe(debounceTime(250))
    .subscribe( data =>  {
      const value = data.searchValue
      this.getRefDossiers(value)
    })
  }

  getRefDossiers(value:string)
  {
    this.dossiersService.getRefDossiers(this.idService, value).subscribe( data => {
      this.liste_dossiers = data
      this.isVisible = true
    })
  }

  goToDetails(ref:string, id:number, type:string)
  {
    Array.from(document.querySelectorAll(".active"))
      .filter(item => item.classList.remove("active"))

    document.querySelector(".dossiers")?.classList.add("active")

    this.searchForm.get("searchValue")?.patchValue("")
    
    this.last_item = ref
    this.last_item_id = id

    if(type == 'dossiers')
    {
      this.navigationService.navigateByURL(`dossiers/details/ref_${ref}/id_dossier_${id}/onglet_suivi`, [null])
    }
    else if(type == 'partenariat')
    {
      this.navigationService.navigateByURL(`partenariat/details/ref_${ref}/id_dip_${id}/onglet_suivi`, [null])
    }
  }
}
